import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBasicParams, ResponseApi, ResponseListApi } from '@core/models/interfaces/common';
import {
  CreatePermissionGroup,
  PermissionGroup,
  PermissionGroupDetails
} from '@core/models/interfaces/permission/group';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private baseUrl = `${environment.API_URL}/crm/groups`;

  constructor(private httpClient: HttpClient) {}

  getGroupById(id: string) {
    return this.httpClient.get<ResponseApi<PermissionGroupDetails>>(`${this.baseUrl}/${id}`);
  }

  updateGroupById(body: CreatePermissionGroup, id: string) {
    return this.httpClient.put<ResponseApi<PermissionGroupDetails>>(`${this.baseUrl}/${id}`, body);
  }

  deleteGroupById(id: string) {
    return this.httpClient.delete(`${this.baseUrl}/${id}`);
  }

  updatePermissionsForGroup(id: string, permissions: string[]) {
    return this.httpClient.put(`${this.baseUrl}/${id}/permission`, permissions);
  }

  updateMembersForGroup(
    id: string,
    body: {
      memberAddIds: string[];
      memberDeleteIds: string[];
    }
  ) {
    return this.httpClient.put(`${this.baseUrl}/${id}/members`, body);
  }

  getGroups(params?: ApiBasicParams) {
    let httpParams = new HttpParams();
    if (params?.keyword) {
      httpParams = httpParams.append('keyword', params?.keyword);
    }
    if (params?.page) {
      httpParams = httpParams.append('page', params?.page);
    }
    if (params?.size) {
      httpParams = httpParams.append('size', params?.size);
    }
    if (params?.sorts) {
      httpParams = httpParams.append('sorts', params?.sorts);
    }

    return this.httpClient.get<ResponseListApi<PermissionGroup>>(`${this.baseUrl}`, {
      params: httpParams
    });
  }

  createGroup(body: CreatePermissionGroup) {
    return this.httpClient.post<ResponseApi<PermissionGroupDetails>>(`${this.baseUrl}`, body);
  }

  cloneGroupById(id: string) {
    return this.httpClient.post<ResponseApi<PermissionGroupDetails>>(`${this.baseUrl}/clone/${id}`, id);
  }
}
